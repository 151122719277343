export function useInterval(fn, delay) {
  const callback = useEvent(fn)

  React.useEffect(() => {
    callback()

    const interval = setInterval(callback, delay)

    return () => {
      clearInterval(interval)
    }
  }, [callback, delay])
}

function useEvent(fn) {
  const fnRef = React.useRef(null)
  fnRef.current = fn

  return React.useCallback((...args) => fnRef.current(...args), [])
}
