import { animated, useSpring } from '@react-spring/web'
import { pseudoRandom, lerp, sequence } from '@kaliber/math'
import { useElementSize } from '@kaliber/use-element-size'
import { useInterval } from '/machinery/useInterval'

import styles from './Blobs.css'

export function BlobsWithRandomTransparentGaps({ layoutClassName = undefined }) {
  return (
    <Base
      withRandomGaps
      gap={25}
      blobElementHeight={150}
      colorVariable='var(--cta-color)'
      className={cx(styles.componentWithRandomTransparentGaps, layoutClassName)}
    />
  )
}

export function BlobsElement({ layoutClassName = undefined }) {
  return <Base gap={10} blobElementHeight={100} componentWidth={250} colorVariable='var(--cta-color)' className={layoutClassName} />
}

export function Blobs({ layoutClassName = undefined }) {
  return <Base gap={10} blobElementHeight={100} colorVariable='var(--cta-background-color)' className={layoutClassName} />
}

function Base({ gap, blobElementHeight, colorVariable, className, componentWidth = undefined, withRandomGaps = undefined }) {
  const { size: { height: elementHeight, width: measuredWidth }, ref: elementRef } = useElementSize()

  const rowCount = Math.floor(elementHeight / blobElementHeight)
  const heightIncludingGaps = `${(rowCount * blobElementHeight) + ((rowCount - 1) * gap)}px`

  return (
    <div ref={elementRef} {...{ className }} role='presentation'>
      <svg width="100%" style={{ height: heightIncludingGaps, fill: colorVariable }}>
        {sequence(rowCount).map((_, index) =>
          <BlobsContainer
            key={`group${index}`}
            elementHeight={blobElementHeight}
            width={componentWidth || measuredWidth}
            {...{ index, gap, withRandomGaps }}
          />
        )}
      </svg>
    </div>
  )
}

function BlobsContainer({ index, gap, elementHeight, width, withRandomGaps }) {
  const [items, setItems] = React.useState([])
  const iterationRef = React.useRef(0)

  const generate = React.useCallback(
    () => generateItems({
      groupIndex: index,
      iteration: iterationRef.current,
      count: items.length,
      withRandomGaps,
      elementHeight,
      width,
      gap
    }),
    [index, gap, elementHeight, width, items.length, withRandomGaps]
  )

  useInterval(
    () => {
      iterationRef.current += 1
      setItems(generate())
    },
    lerp({ start: 2500, end: 6000, input: pseudoRandom(index) })
  )

  React.useEffect(
    () => setItems(generate()),
    [generate]
  )

  return (
    <React.Fragment>
      {items.map(({ left, visible, itemWidth }, i) =>
        <Blob
          key={`${index}/${i}`}
          elementWidth={itemWidth}
          top={index * (elementHeight + gap)}
          {...{ elementHeight, left, visible }}
        />
      )}
    </React.Fragment>
  )
}

function Blob({ top, left, color, visible, elementWidth, elementHeight }) {
  const smallestCornerRadius = Math.min(elementWidth / 2, elementHeight / 2)
  const { width, x, radius } = useSpring({
    radius: smallestCornerRadius,
    width: elementWidth,
    x: left
  })

  return (
    <animated.rect
      style={{ fill: !visible && 'transparent', y: top, width, x }}
      height={elementHeight}
      rx={radius}
      ry={radius}
    />
  )
}

function generateItems({ groupIndex, iteration, count, elementHeight, withRandomGaps, width, gap }) {
  const amountOfItems = Math.ceil((width / 80))
  const maximumWidth = width - (count - 1) * gap

  const randomItems = getRandom({
    min: elementHeight / 10,
    max: elementHeight * 3,
    count: amountOfItems,
    availableWidth: maximumWidth,
    seed: iteration * 10E9 + groupIndex
  })

  const shuffledItems = shuffleArray({ array: randomItems, seed: groupIndex + iteration })
    .reduce((acc, itemWidth, i) => ([ ...acc, {
      left: acc.reduce((a, { itemWidth }) => a + itemWidth + gap, 0),
      itemWidth,
      visible:
        withRandomGaps
          ? pseudoRandom(i + iteration) > 0.25
          : true
    }]), [])

  return shuffledItems
}

function getRandom({ seed, min, max, count, availableWidth }) {
  return sequence(count)
    .map((i) => {
      const remainingItems = count - i - 1
      const maximumSize = Math.min(max, availableWidth - remainingItems * min)
      const randomSize = lerp({ start: min, end: maximumSize, input: pseudoRandom(seed + i) })

      availableWidth -= randomSize

      return randomSize
    })
}

function shuffleArray({ array, seed }) {
  sequence(array.length).forEach((i) => {
    const j = Math.floor(lerp({ start: 0, end: array.length, input: pseudoRandom(seed + i) }));
    [array[i], array[j]] = [array[j], array[i]]
  })

  return array
}
